import { useState, useEffect } from "react";
import {
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} from "./groupsApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";

const EditGroupForm = ({ group }) => {
  const [updateGroup, { isLoading, isSuccess, isError, error }] =
    useUpdateGroupMutation();

  const [
    deleteGroup,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteGroupMutation();

  const navigate = useNavigate();

  const [name, setName] = useState(group.name);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setName("");
      navigate("/dash/groups");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onNameChanged = (e) => setName(e.target.value);
  const onCancelClicked = (e) => navigate('/dash/groups');

  const canSave = [name].every(Boolean) && !isLoading;

  const onSaveGroupClicked = async (e) => {
    if (canSave) {
      await updateGroup({ id: group.id, name});
    }
  };

  const onDeleteGroupClicked = async () => {
    await deleteGroup({ id: group.id });
  };

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validNameClass = !name ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let deleteButton = (
    <button className="icon-button" title="حذف" onClick={onDeleteGroupClicked}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
  let cancelButton = (
    <button className="icon-button" title="إلغاء" onClick={onCancelClicked}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form__title-row">
          <h2>تعديل مجموعة</h2>
          <div className="form__action-buttons">
            {cancelButton}
            <button
              className="icon-button"
              title="حفظ"
              onClick={onSaveGroupClicked}
              disabled={!canSave}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            {deleteButton}
          </div>
        </div>
        <label className="form__label" htmlFor="group-title">
          الاسم:
        </label>
        <input
          className={`form__input ${validNameClass}`}
          id="group-name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={onNameChanged}
        />
      </form>
    </>
  );

  return content;
};

export default EditGroupForm;
