

import { useGetPaymentsQuery } from './paymentsApiSlice'
import { memo } from 'react'
import SingleOperation from '../../components/SingleOperation'

const Payment = ({ paymentId, payment }) => {

    // const { payment } = useGetPaymentsQuery("paymentsList", {
    //     selectFromResult: ({ data }) => ({
    //         payment: data?.entities[paymentId]
    //     }),
    // })


    if (payment) {

        return (
                <SingleOperation key={payment.id} income={payment} isIncome={false} />
        )

    } else return null
}

const memoizedPayment = memo(Payment)

export default memoizedPayment