import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetGroupsQuery } from './groupsApiSlice'
import { memo } from 'react'

const Group = ({ groupId }) => {

    const { group } = useGetGroupsQuery("groupsList", {
        selectFromResult: ({ data }) => ({
            group: data?.entities[groupId]
        }),
    })

    const navigate = useNavigate()

    if (group) {

        const handleEdit = () => navigate(`/dash/groups/${groupId}`)

        return (
            <tr className="table__row">
                <td className="table__cell note__title">{group.name}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedGroup = memo(Group)

export default memoizedGroup