import { useNavigate } from "react-router-dom";
import { useGetPaymentsQuery } from "./paymentsApiSlice";
import Payment from "./Payment";
// import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";

import Loading from "../../components/Loading";

const PaymentsList = () => {
  useTitle("المصروفات");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/payments/new");
  };

  // const { username, isManager, isAdmin } = useAuth()

  const {
    data: payments,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetPaymentsQuery("paymentsList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No payment found") {
      return (content = (
        <>
          <button className="dash__main__btn" onClick={handleBtn}>
            صرف جديد
          </button>
          <p>لا يوجد مصروفات</p>
        </>
      ));
    }
    content = <p className="errmsg">خطأ - {error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = payments;

    let paymentList =
      ids?.length &&
      ids.map((paymentId) => <Payment key={paymentId} paymentId={paymentId} payment={payments?.entities[paymentId]} />);

    content = (
      <>
        <button className="dash__main__btn" onClick={handleBtn}>
          صرف
        </button>
        {paymentList}
      </>
    );
  }

  return content;
};
export default PaymentsList;
