import { useGetIncomesQuery } from "../features/incomes/incomesApiSlice";
import { useGetPaymentsQuery } from "../features/payments/paymentsApiSlice";
import Loading from "./Loading";
import SingleOperation from "./SingleOperation";

function LastOperations() {
  const {
    data: payments,
    isLoading,
    isError,
    isSuccess,
  } = useGetPaymentsQuery("paymentsList",{
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const {
    data: incomes,
    isLoading: isLoadingIncomes,
    isError: isErrorIncomes,
    isSuccess: isSuccessIncome,
  } = useGetIncomesQuery("incomesList",{
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (!incomes && !payments) return <p>لايوجد عمليات أخيرة</p>;

  return (
    <section>
      <h6 style={{margin: '0 auto', maxWidth: '768px'}}>المدفوعات</h6>
      
      {isLoadingIncomes ? <Loading /> : isErrorIncomes ? <p>لايوجد عمليات أخيرة</p> : isSuccessIncome && incomes?.ids.map((id) => incomes?.entities[id]).slice(0, 3).map((income) => (
        <SingleOperation key={income.id} title={'المدفوعات'} income={income} isIncome={true} />
      ))}
      <h6 style={{margin: '0 auto', maxWidth: '768px'}}>المصروفات</h6>
      {isLoading ? <Loading /> : isError ? <p>لايوجد عمليات أخيرة</p> : isSuccess && payments?.ids.map((id) => payments?.entities[id]).slice(0, 3).map((income) => (
        <SingleOperation key={income.id} title={'المصروفات'} income={income} isIncome={false} />
      ))}
    </section>
  );
}

export default LastOperations;
