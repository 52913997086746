
import { useGetMembersQuery } from "../members/membersApiSlice";
import { useGetBudgetsQuery } from "../budgets/budgetsApiSlice";
import { useGetGroupsQuery } from "../groups/groupsApiSlice";
import useTitle from "../../hooks/useTitle";
import Loading from "../../components/Loading"
import NewIncomeForm from "./NewIncomeForm"


const NewIncome = () => {
  useTitle("ايداع جديد");

  const { budgets } = useGetBudgetsQuery("budgetsList", {
    selectFromResult: ({ data }) => ({
      budgets: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const { members } = useGetMembersQuery("membersList", {
    selectFromResult: ({ data }) => ({
      members: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const { groups } = useGetGroupsQuery("groupsList", {
    selectFromResult: ({ data }) => ({
      groups: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  // wait for the data
  if(!budgets || !members) return <Loading />

  const content = (
    <>
      <NewIncomeForm members={members} budgets={budgets} groups={groups} />
    </>
  );

  return content;
};
export default NewIncome;
