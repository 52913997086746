import { Link } from "react-router-dom"

function NotAllowed() {
    return (
        <div
          style={{
            display: "grid",
            height:'100vh',
            justifyItems: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{maxWidth: '450px'}}>
            <p className="errmsg" style={{textAlign: 'center'}}>
              "غير مصرح لك"
            </p>
            <div style={{textAlign: 'center'}}>
              <Link to="/" style={{border: '1px solid #ccc', padding: '10px 20px'}}>الصفحة الرئيسية</Link>
            </div>
          </div>
        </div>
      );
}

export default NotAllowed