import { useNavigate } from "react-router-dom";
import { useGetBudgetsQuery } from "./budgetsApiSlice";
import Budget from "./Budget";
// import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";
import DashModel from "../../components/DashModel";

import noBudgetImg from "../../img/money-safe-gold-bar.png";
import Loading from "../../components/Loading";
const BudgetsList = () => {
  useTitle("الميزانيات");

  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/budgets/new");
  };
  // const { username, isManager, isAdmin } = useAuth()

  const {
    data: budgets,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBudgetsQuery("budgetsList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });


  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No budgets found") {
      return (content = (
        <DashModel
          title={"لا يوجد ميزانية"}
          body={"تبين أن لم يتم انشاء أي ميزانية"}
          btnText={"انشاء جديد"}
          img={noBudgetImg}
          link={"/dash/budgets/new"}
        />
      ));
    }
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = budgets;
    const tableContent =
      ids?.length &&
      ids.map((budgetId) => <Budget key={budgetId} budgetId={budgetId} budget={budgets.entities[budgetId]} />);

    content = (
      <>
        <button className="dash__main__btn" onClick={handleBtn}>
          اضافة ميزانية
        </button>
        <table className="table table--notes">
          <thead className="table__thead">
            <tr>
              <th scope="col" className="table__th note__status">
                الحالة
              </th>
              <th scope="col" className="table__th note__title">
                الاسم
              </th>
              <th scope="col" className="table__th note__username">
                العام
              </th>
              <th scope="col" className="table__th note__edit">
                تعديل
              </th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </>
    );
  }

  return content;
};
export default BudgetsList;
