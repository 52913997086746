

import { useGetBudgetsQuery } from "../budgets/budgetsApiSlice";
import { useGetReasonsQuery } from "../reasons/reasonsApiSlice";
import useTitle from "../../hooks/useTitle";
import Loading from "../../components/Loading"
import NewPaymentForm from "./NewPaymentForm"


const NewPayment = () => {
  useTitle("صرف جديد");

  const { budgets } = useGetBudgetsQuery("budgetsList", {
    selectFromResult: ({ data }) => ({
      budgets: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const { reasons = [] } = useGetReasonsQuery("reasonsList", {
    selectFromResult: ({ data }) => ({
      reasons: data?.ids.map((id) => data?.entities[id]),
    }),
  });


  // wait for the data
  if(!budgets || !reasons) return <Loading />

  const content = (
    <>
      <NewPaymentForm reasons={reasons} budgets={budgets} />
    </>
  );

  return content;
};
export default NewPayment;
