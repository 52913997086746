import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTitle from "../../hooks/useTitle";
import NewReasonForm from "./NewReasonForm";

import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const NewReason = () => {
  useTitle("بند جديد");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/reasons");
  };


  const content = (
    <>
      <button className="dash__back__btn" onClick={handleBtn}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} />
        العودة للبنود
      </button>
      <NewReasonForm />
    </>
  );

  return content;
};
export default NewReason;
