import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import Loading from './Loading'

// const DASH_REGEX = /^\/dash(\/)?$/
// const BUDGETS_REGEX = /^\/dash\/budgets(\/)?$/
// const USERS_REGEX = /^\/dash\/users(\/)?$/

const DashHeader = () => {
    // const { isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    // const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])


    const logoutButton = (
        <button
            className="icon-button"
            title="تسجيل الخروج"
            onClick={sendLogout}
        >
            <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
    )

    const errClass = isError ? "errmsg" : "offscreen"

    let buttonContent
    if (isLoading) {
        buttonContent = <Loading />
    } else {
        buttonContent = (
            <>
                {logoutButton}
            </>
        )
    }

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <header className="dash-header">
                <div className='dash-header__container'>
                    <Link to="/dash">
                        <h1 className="dash-header__title">الصندوق العائلي</h1>
                    </Link>
                    <nav className="dash-header__nav">
                        {buttonContent}
                    </nav>
                </div>
            </header>
        </>
    )

    return content
}
export default DashHeader