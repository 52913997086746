import { useNavigate } from "react-router-dom";
import { useGetIncomesQuery } from "./incomesApiSlice";
import Income from "./Income";
// import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";

import Loading from "../../components/Loading";

const IncomesList = () => {
  useTitle("الايداعات");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/incomes/new");
  };


  const {
    data: incomes,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetIncomesQuery("incomesList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No income found") {
      return (content = (
        <>
          <button className="dash__main__btn" onClick={handleBtn}>
            ايداع
          </button>
          <p>لا يوجد ايداعات</p>
        </>
      ));
    }
    content = <p className="errmsg">خطأ - {error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = incomes;

    let incomeList =
      ids?.length &&
      ids.map((incomeId) => <Income key={incomeId} incomeId={incomeId} income={incomes?.entities[incomeId]} />);

    content = (
      <>
        <button className="dash__main__btn" onClick={handleBtn}>
          ايداع
        </button>
        {incomeList}
      </>
    );
  }

  return content;
};
export default IncomesList;
