import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTitle from "../../hooks/useTitle";
import NewGroupForm from "./NewGroupForm";

import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const NewGroup = () => {
  useTitle("محموعة جديدة");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/groups");
  };


  const content = (
    <>
      <button className="dash__back__btn" onClick={handleBtn}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} />
        العودة للمجموعات
      </button>
      <NewGroupForm />
    </>
  );

  return content;
};
export default NewGroup;
