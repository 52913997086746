import PulseLoader from 'react-spinners/PulseLoader'

function Loading() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <PulseLoader color={"#2a9ca6"} />
    </div>
  )
}

export default Loading