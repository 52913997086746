function ReportSecondTable({ budgets, groups, member }) {
    // console.log(member)
    // console.log(budgets[0].members)
    const tableContent = budgets.map((budget) => (
        
        <tr key={budget.id} className="table__row">
            <td className="table__cell table__cell-report">{budget.name}</td>
            <td className="table__cell table__cell-report">{member.name}</td>
            <td className="table__cell table__cell-report">
                {budget.members?.some(
                    (budgetMember) => budgetMember._id.toString() === member._id.toString()
                )
                    ? "لم يدفع"
                    : budget.paidMembers?.some(
                          (m) => m._id.toString() === member._id.toString()
                      )
                    ? "تم الدفع"
                    : "غير مشترك"}
            </td>
            <td className="table__cell table__cell-report">
            {budget.members?.some(
                    (budgetMember) => budgetMember._id.toString() === member._id.toString()
                )
                    ? budget.amountForMember
                    : budget.paidMembers?.some(
                          (m) => m._id.toString() === member._id.toString()
                      )
                    ? budget.amountForMember
                    : 0}
            </td>
        </tr>
    ));
    return (
        <div
            style={{
                overflow: "scroll",
                marginTop: "20px",
                overflowY: "hidden",
            }}
        >
            <table className="table table-first-report">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th table__th-report">
                            الميزانية
                        </th>
                        <th scope="col" className="table__th table__th-report">
                            اسم العضو
                        </th>
                        <th scope="col" className="table__th table__th-report">
                            الحالة
                        </th>
                        <th scope="col" className="table__th table__th-report">
                            المبلغ
                        </th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </table>
        </div>
    );
}

export default ReportSecondTable;
