import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'

import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
import MembersList from './features/members/MembersList'
import EditMember from './features/members/EditMember'
import NewMember from './features/members/NewMember'
import GroupsList from './features/groups/GroupsList'
import EditGroup from './features/groups/EditGroup'
import NewGroup from './features/groups/NewGroup'
import BudgetsList from './features/budgets/BudgetsList'
import EditBudget from './features/budgets/EditBudget'
import NewBudget from './features/budgets/NewBudget'
import BoxesList from './features/boxes/BoxesList'
import EditBox from './features/boxes/EditBox'
import NewBox from './features/boxes/NewBox'
import NewIncome from './features/incomes/NewIncome';
import IncomesList from './features/incomes/IncomesList'
import NewPayment from './features/payments/NewPayment';
import PaymentsList from './features/payments/PaymentsList'
import ReasonsList from './features/reasons/ReasonsList'
import EditReason from './features/reasons/EditReason'
import NewReason from './features/reasons/NewReason'
import ReportsIndex from './features/reports/ReportsIndex'

import MoreInfo from './components/MoreInfo';

import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle';
import NotFound from './components/NotFound';

function App() {
  useTitle('الصندوق العائلي')

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
            <Route element={<Prefetch />}>
              <Route path="dash" element={<DashLayout />}>

                <Route index element={<Welcome />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewUserForm />} />
                  </Route>
                </Route>

                <Route path="boxes">
                  <Route index element={<BoxesList />} />
                  <Route path=":id" element={<EditBox />} />
                  <Route path="new" element={<NewBox />} />
                </Route>

                <Route path="incomes">
                  <Route index element={<IncomesList />} />
                  <Route path="new" element={<NewIncome />} />
                </Route>

                <Route path="payments">
                  <Route index element={<PaymentsList />} />
                  <Route path="new" element={<NewPayment />} />
                </Route>

                <Route path="budgets">
                  <Route index element={<BudgetsList />} />
                  <Route path=":id" element={<EditBudget />} />
                  <Route path="new" element={<NewBudget />} />
                </Route>

                <Route path="members">
                  <Route index element={<MembersList />} />
                  <Route path=":id" element={<EditMember />} />
                  <Route path="new" element={<NewMember />} />
                </Route>

                <Route path="groups">
                  <Route index element={<GroupsList />} />
                  <Route path=":id" element={<EditGroup />} />
                  <Route path="new" element={<NewGroup />} />
                </Route>

                <Route path="reasons">
                  <Route index element={<ReasonsList />} />
                  <Route path=":id" element={<EditReason />} />
                  <Route path="new" element={<NewReason />} />
                </Route>

                <Route path="more">
                  <Route index element={<MoreInfo />} />
                </Route>

                <Route path="reports">
                  <Route index element={<ReportsIndex />} />
                </Route>

                <Route path='*' element={<NotFound />} />
              </Route>{/* End Dash */}
            </Route>
          </Route>
        </Route>{/* End Protected Routes */}

        {/* catch all -- add 404 page here */}
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes >
  );
}

export default App;
