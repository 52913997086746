import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewPaymentMutation } from "./paymentsApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewPaymentForm = ({ budgets, reasons }) => {

  const [addNewPayment, { isLoading, isSuccess, isError, error }] =
    useAddNewPaymentMutation();

  const navigate = useNavigate();

  const today = new Date();

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(
    today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
  );
  const [currentBudget, setCurrentBudget] = useState("");
  const [currentReason, setCurrentReason] = useState("");



  useEffect(() => {
    if (isSuccess) {
      setAmount(0);
      setDescription("");
      setDate(null);
      setCurrentBudget("");
      navigate("/dash/payments");
    }
  }, [isSuccess, navigate]);

  const onAmountChanged = (e) => setAmount(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);
  const onDateChanged = (e) => setDate(e.target.value);
  const onBudgetChanged = (e) => setCurrentBudget(e.target.value);
  const onReasonChanged = (e) => setCurrentReason(e.target.value);

  const canSave =
    [amount, currentBudget, currentReason].every(Boolean) && !isLoading;

  const onSavePaymentClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewPayment({
        amount: Number(amount),
        reasonId: currentReason,
        budgetId: currentBudget,
        description,
        date,
      });
    }
  };

  const budgetOptions = budgets.map((budget) => {
    return (
      <option key={budget.id} value={budget.id}>
        {" "}
        {budget.name}
      </option>
    );
  });

  const reasonOptions = reasons.map((reason) => {
    return (
      <option key={reason.id} value={reason.id}>
        {" "}
        {reason.name}
      </option>
    );
  });


  const errClass = isError ? "errmsg" : "offscreen";
  const validAmountClass = !amount ? "form__input--incomplete" : "";
  const validBudgetClass = !currentBudget ? "form__input--incomplete" : "";
  const validReasonClass = !currentReason ? "form__input--incomplete" : "";

  const content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="form" onSubmit={onSavePaymentClicked}>
        <div className="form__title-row">
          <h2>صرف جديد</h2>
          <div className="form__action-buttons">
            <button className="icon-button" title="Save" disabled={!canSave}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </div>
        <label
          className="form__label form__checkbox-container"
          htmlFor="budget"
        >
          الميزانية: <span style={{fontSize: '10px', color: 'gray'}}>فقط للإطلاع ولن يتم الصرف إلا من اجمالي الرصيد</span>
        </label>
        <select
          id="budget"
          name="budget"
          className={`form__select  ${validBudgetClass}`}
          onChange={onBudgetChanged}
        >
          <option value="">اختر ميزانية</option>
          {budgetOptions}
        </select>
        <label
          className="form__label form__checkbox-container"
          htmlFor="reason"
        >
          بند الصرف:
        </label>
        <select
          id="reason"
          name="reason"
          className={`form__select  ${validReasonClass}`}
          onChange={onReasonChanged}
        >
          <option value="">اختر بند الصرف</option>
          {reasonOptions}
        </select>

        <label className="form__label" htmlFor="amount">
          المبلغ:
        </label>
        <input
          className={`form__input ${validAmountClass}`}
          id="amount"
          name="amount"
          type="number"
          pattern="[0-9]*"
          autoComplete="off"
          value={amount}
          onChange={onAmountChanged}
        />
        <label className="form__label" htmlFor="description">
          ملاحظات:
        </label>
        <input
          className={`form__input`}
          id="description"
          name="description"
          type="text"
          autoComplete="off"
          value={description}
          onChange={onDescriptionChanged}
        />
        <label className="form__label" htmlFor="date">
          تاريخ الصرف:
        </label>
        <input
          className={`form__input`}
          id="date"
          name="date"
          type="date"
          autoComplete="off"
          value={date}
          onChange={onDateChanged}
        />
      </form>
    </>
  );

  return content;
};

export default NewPaymentForm;
