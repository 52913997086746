import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { memo } from 'react'

const Member = ({ memberId, member }) => {

    const navigate = useNavigate()

    if (member) {

        const handleEdit = () => navigate(`/dash/members/${memberId}`)

        return (
            <tr className="table__row">
                <td className="table__cell note__status">
                    {member.isActive
                        ? <span className="note__status--open">مفعل</span>
                        : <span className="note__status--closed">غير مفعل</span>
                    }
                </td>
                <td className="table__cell note__title">{member.name} - {member?.group?.name}</td>
                <td className="table__cell note__username">{member.phone}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedMember = memo(Member)

export default memoizedMember