import { useGetGroupsQuery } from '../groups/groupsApiSlice'
import useTitle from '../../hooks/useTitle'
import NewMemberForm from './NewMemberForm'

const NewMember = () => {
    useTitle('عضو جديد')

    const { groups } = useGetGroupsQuery("groupsList", {
        selectFromResult: ({ data }) => ({
            groups: data?.ids.map(id => data?.entities[id])
        }),
    })

    const content = <NewMemberForm groups={groups} />

    return content
}
export default NewMember