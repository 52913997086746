import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const incomesAdapter = createEntityAdapter({})

const initialState = incomesAdapter.getInitialState()

export const incomesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIncomes: builder.query({
            query: () => ({
                url: '/incomes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const sortedResData = responseData.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const loadedIncomes = sortedResData.map(income => {
                    income.id = income._id
                    return income
                });
                return incomesAdapter.setAll(initialState, loadedIncomes)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Income', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Income', id }))
                    ]
                } else return [{ type: 'Income', id: 'LIST' }]
            }
        }),
        getBudgetIncomes: builder.query({
            query: (budgetId) => ({
                url: `/incomes/${budgetId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            })
        }),
        getTotalIncomes: builder.query({
            query: () => ({
                url: '/incomes/total',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            })
        }),
        addNewIncome: builder.mutation({
            query: initialIncomeData => ({
                url: '/incomes',
                method: 'POST',
                body: {
                    ...initialIncomeData,
                }
            }),
            invalidatesTags: [
                { type: 'Income', id: "LIST" }, { type: 'Budget', id: "LIST" }
            ]
        }),
        updateIncome: builder.mutation({
            query: initialIncomeData => ({
                url: '/incomes',
                method: 'PATCH',
                body: {
                    ...initialIncomeData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Income', id: arg.id }
            ]
        }),
        deleteIncome: builder.mutation({
            query: ({ id }) => ({
                url: `/incomes`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Income', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetIncomesQuery,
    useGetBudgetIncomesQuery,
    useGetTotalIncomesQuery,
    useAddNewIncomeMutation,
    useUpdateIncomeMutation,
    useDeleteIncomeMutation,
} = incomesApiSlice

// returns the query result object
export const selectIncomesResult = incomesApiSlice.endpoints.getIncomes.select()

// creates memoized selector
const selectIncomesData = createSelector(
    selectIncomesResult,
    incomesResult => incomesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllIncomes,
    selectById: selectIncomeById,
    selectIds: selectIncomeIds
    // Pass in a selector that returns the incomes slice of state
} = incomesAdapter.getSelectors(state => selectIncomesData(state) ?? initialState)