import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetReasonsQuery } from './reasonsApiSlice'
import { memo } from 'react'

const Reason = ({ reasonId }) => {

    const { reason } = useGetReasonsQuery("reasonsList", {
        selectFromResult: ({ data }) => ({
            reason: data?.entities[reasonId]
        }),
    })

    const navigate = useNavigate()

    if (reason) {

        const handleEdit = () => navigate(`/dash/reasons/${reasonId}`)

        return (
            <tr className="table__row">
                <td className="table__cell note__title">{reason.name}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedReason = memo(Reason)

export default memoizedReason