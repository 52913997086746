
import { Link } from 'react-router-dom'
import ActionButton from './ActionButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

function MoreInfo() {
  return (
    <div>
        <Link to="/dash/reasons">
            <ActionButton title={'بنود الصرف'}>
                <FontAwesomeIcon icon={faHandHoldingDollar} />
            </ActionButton>
        </Link>
    </div>
  )
}

export default MoreInfo