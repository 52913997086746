
import './greeting.css'
import useAuth  from '../hooks/useAuth'

function Greeting() {

    const { name } = useAuth()
    let word = 'صباح'
    const today = new Date();
    if(today.getHours() > 12){
        word = 'مساء'
    }
  return (
    <div className="greeting__container">
        <div className="greeting__header">
            <span>👋🏼 {word} الخير</span>
        </div>
            <h2>{name}</h2>
    </div>
  )
}

export default Greeting