import { useNavigate } from "react-router-dom";
import { useGetMembersQuery } from "./membersApiSlice";
import Member from "./Member";
// import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";
import DashModel from '../../components/DashModel'

import noMemberImg from '../../img/member.png'
import Loading from "../../components/Loading";

const MembersList = () => {
    useTitle("الاعضاء");

    const navigate = useNavigate();
    const handleBtn = () => {
      navigate("/dash/members/new");
    };

  // const { username, isManager, isAdmin } = useAuth()

  const {
    data: members,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMembersQuery("membersList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  

  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No members found") {
      return content = <DashModel title={"لا يوجد اعضاء"} body={"لا يوجد اعضاء بالنظام"} btnText={"انشاء جديد"} img={noMemberImg} link={'/dash/members/new'} />
    }
    content = <p className="errmsg">خطأ - {error?.data?.message}</p>;
  }

  if (isSuccess) {
    // console.log(members)
    const { ids } = members;

    const tableContent =
      ids?.length &&
      ids.map((memberId) => <Member key={memberId} memberId={memberId} member={members?.entities[memberId]} />);

    content = (
        <>
            <button className="dash__main__btn" onClick={handleBtn}>
            اضافة عضو
            </button>
        <table className="table table--notes">
            <thead className="table__thead">
            <tr>
                <th scope="col" className="table__th note__status">
                الحالة
                </th>
                <th scope="col" className="table__th note__title">
                الاسم
                </th>
                <th scope="col" className="table__th note__username">
                رقم الجوال
                </th>
                <th scope="col" className="table__th note__edit">
                تعديل
                </th>
            </tr>
            </thead>
            <tbody>{tableContent}</tbody>
        </table>
        </>
    );
  }

  return content;
};
export default MembersList;
