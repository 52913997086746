
import { useGetBoxesQuery } from '../boxes/boxesApiSlice'
import useTitle from '../../hooks/useTitle'
import DashModel from '../../components/DashModel'
import noBoxImg from '../../img/money-safe-gold-bar.png'
import Loading from '../../components/Loading'
import BalanceCard from '../../components/BalanceCard'
import Greeting from '../../components/Greeting'
import DashCard from '../../components/DashCard'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVault, faSackDollar, faPeopleRoof, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom'
const Welcome = () => {

    const {
        data: boxes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetBoxesQuery('boxesList', {
        // pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })


    useTitle('الصندوق العائلي')


    let content

    if (isLoading) content = <Loading />

    if (isError) {
        // console.log(error)
        if(error?.data?.message === "No boxes found"){
            return content = <DashModel title={"لا يوجد صندوق"} body={"لايوجد صندوق مرتبط بالمستخدم"} btnText={"انشاء جديد"} img={noBoxImg} link={"/dash/boxes/new"} />
        }
    }

    if(isSuccess){
        const { ids: boxIds } = boxes
        // console.log(boxIds)
            content = (
                <>
                    <Greeting />
                    {/* <Link to='/dash/boxes'> */}
                        {/* <BalanceCard boxId={boxIds[0]} text={"اضغط هنا للوصول للتفاصيل"} /> */}
                        <BalanceCard boxId={boxIds[0]}  />
                    {/* </Link> */}
                    <div className="welcome__dash-cards-container">
                        <Link to='/dash/boxes'>
                            <DashCard title={"الصندوق"}>
                                <FontAwesomeIcon icon={faVault} />
                            </DashCard>
                        </Link>
                        <Link to='/dash/budgets'>
                            <DashCard title={'الميزانيات'}>
                                <FontAwesomeIcon icon={faSackDollar} />
                            </DashCard>
                        </Link>
                        <Link to='/dash/members'>
                            <DashCard title={'الاعضاء'}>
                                <FontAwesomeIcon icon={faPeopleRoof} />
                            </DashCard>
                        </Link>
                        <Link to='/dash/groups'>
                            <DashCard title={'المجموعات'}>
                                <FontAwesomeIcon icon={faUsersRectangle} />
                            </DashCard>
                        </Link>
                    </div>
                </>
            )
        return (
            <section className="welcome">
                {content}
    
            </section>
        )
    }

    return content

}
export default Welcome