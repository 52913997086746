import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewIncomeMutation } from "./incomesApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewIncomeForm = ({ budgets, members, groups }) => {
  //   console.log(members);

  const [addNewIncome, { isLoading, isSuccess, isError, error }] =
    useAddNewIncomeMutation();

  const navigate = useNavigate();

  const today = new Date();

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(
    today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
  );
  const [currentBudget, setCurrentBudget] = useState("");
  const [payMembers, setPayMembers] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [optionGroups, setOptionGroups] = useState([]);
  const [groupId, setGroupId] = useState("all");
  const [selectAllStatus, setSelectAllStatus] = useState(false);



  useEffect(() => {
    if (isSuccess) {
      setAmount(0);
      setDescription("");
      setDate(null);
      setCurrentBudget("");
      setPayMembers([]);
      setCurrentMembers([]);
      navigate("/dash/incomes");
    }
  }, [isSuccess, navigate]);

  const resetCheckboxes = ()=> {
    setPayMembers([])
    setSelectAllStatus(false)
    currentMembers.forEach(member => document.getElementById(`member-${member.id}`).checked = false)
    if(currentMembers.length > 1){
        document.getElementById(`allMembers`).checked = false
    }
  }

  const onAmountChanged = (e) => setAmount(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);
  const onDateChanged = (e) => setDate(e.target.value);
  const onPayMemberChanged = (e) => {
      const foundMember = payMembers.find((m) => m === e.target.value);
      if (foundMember) {
      setPayMembers((prevState) =>
        prevState.filter((m) => m !== e.target.value)
      );
      return;
    }
    setPayMembers((prevState) => [...prevState, e.target.value]);
  };
  const onBudgetChanged = (e) => {
    resetCheckboxes()
    setCurrentBudget(e.target.value);
    const targetBudget = budgets.find((b) => b.id === e.target.value);
    // console.log(targetBudget);
    // get groups
    const budgetGroups = targetBudget.members.map((member) => member.group);
    const budgetGroupsId = [...new Set(budgetGroups)];
    setOptionGroups(
      (prevState) =>
        (prevState = groups.filter((group) =>
          budgetGroupsId.includes(group.id)
        ))
    );
    setAmount(targetBudget.amountForMember);

    setCurrentMembers(
      members?.filter((m) =>
        targetBudget.members.map((m) => m.id).includes(m.id)
      )
    );
  };

  const onGroupIdChanged = (e) => {
    resetCheckboxes()
    setGroupId((prevState) => (prevState = e.target.value));
    if (e.target.value === "all") {
      const targetBudget = budgets.find((b) => b.id === currentBudget);
      setCurrentMembers(
        members?.filter((m) =>
          targetBudget.members.map((m) => m.id).includes(m.id)
        )
      );
      return;
    }
    setCurrentMembers(
      (prevState) =>
        (prevState = members.filter((m) => m?.group?._id === e.target.value))
    );
  };

  const canSave =
    [amount, payMembers.length, currentBudget].every(Boolean) && !isLoading;

  const onSaveIncomeClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewIncome({
        amount: Number(amount),
        membersId: payMembers,
        budgetId: currentBudget,
        description,
        date,
      });
    }
  };

  const onSelectAllMembers = ()=> {
    if(!selectAllStatus){
        currentMembers.forEach(member => document.getElementById(`member-${member.id}`).checked = true)
        setSelectAllStatus(true)
        setPayMembers(currentMembers.map(m=> m.id))
    }else{
        currentMembers.forEach(member => document.getElementById(`member-${member.id}`).checked = false)
        setSelectAllStatus(false)
        setPayMembers([])
    }
  }

  const options = optionGroups?.map((group) => {
    return (
      <option key={group._id} value={group._id}>
        {" "}
        {group.name}
      </option>
    );
  });

  const budgetOptions = budgets.map((budget) => {
    return (
      <option key={budget.id} value={budget.id}>
        {" "}
        {budget.name}
      </option>
    );
  });

  const MemberOptions = currentMembers.map((member) => {
    return (
      <div key={member.id}>
        <input
          type="checkbox"
          value={member.id}
          style={{ marginLeft: "5px" }}
          id={`member-${member.id}`}
          onChange={onPayMemberChanged}
        />
        <label htmlFor={`member-${member.id}`}>{member.name}</label>
      </div>
    );
  });

  const errClass = isError ? "errmsg" : "offscreen";
  const validAmountClass = !amount ? "form__input--incomplete" : "";
  const validBudgetClass = !currentBudget ? "form__input--incomplete" : "";
  const validMemberClass = !payMembers.length ? "form__input--incomplete" : "";

  const content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="form" onSubmit={onSaveIncomeClicked}>
        <div className="form__title-row">
          <h2>ايداع جديد</h2>
          <div className="form__action-buttons">
            <button className="icon-button" title="Save" disabled={!canSave}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </div>
        <label
          className="form__label form__checkbox-container"
          htmlFor="budget"
        >
          الميزانية:
        </label>
        <select
          id="budget"
          name="budget"
          className={`form__select  ${validBudgetClass}`}
          onChange={onBudgetChanged}
          value={currentBudget}
        >
          <option value={null}>اختر ميزانية</option>
          {budgetOptions}
        </select>
        <label className="form__label form__checkbox-container">المجموعة:</label>
        <select
          id="groupId"
          name="groupId"
          className="form__select"
          value={groupId}
          onChange={onGroupIdChanged}
        >
          <option value="all">الكل</option>
          {options}
        </select>
        <label className="form__label form__checkbox-container">الاعضاء:</label>
        <div
          style={{
            border: "1px solid #000",
            borderRadius: "15px",
            height: "100px",
            padding: "10px",
            overflow: "scroll",
            overflowX: "hidden",
          }}
          className={validMemberClass}
        >
        {currentMembers?.length > 1 && (

          <div>
            <input
              type="checkbox"
              value="allMembers"
              style={{ marginLeft: "5px" }}
              id="allMembers"
              onChange={onSelectAllMembers}
            />
            <label htmlFor="allMembers">الكل</label>
          </div>
        )}
          {MemberOptions}
        </div>
        <label className="form__label" htmlFor="amount">
          المبلغ:
        </label>
        <input
          className={`form__input ${validAmountClass}`}
          id="amount"
          name="amount"
          type="number"
          pattern="[0-9]*"
          autoComplete="off"
          value={amount}
          onChange={onAmountChanged}
        />
        <label className="form__label" htmlFor="description">
          الوصف:
        </label>
        <input
          className={`form__input`}
          id="description"
          name="description"
          type="text"
          autoComplete="off"
          value={description}
          onChange={onDescriptionChanged}
        />
        <label className="form__label" htmlFor="date">
          تاريخ الايداع:
        </label>
        <input
          className={`form__input`}
          id="date"
          name="date"
          type="date"
          autoComplete="off"
          value={date}
          onChange={onDateChanged}
        />
      </form>
    </>
  );

  return content;
};

export default NewIncomeForm;
