import React from 'react'

function NotFound() {
  return (
    <div>
        <h1>
            الصفحة غير موجودة 🛰
        </h1>

    </div>
  )
}

export default NotFound