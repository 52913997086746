import { useNavigate } from "react-router-dom";
import { useGetBoxesQuery } from "./boxesApiSlice";
import Box from "./Box";
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";
import DashModel from "../../components/DashModel";
import noBoxImg from "../../img/money-safe-gold-bar.png";
import Loading from "../../components/Loading";

const BoxesList = () => {
  useTitle("عرض الصناديق");

  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/boxes/new");
  };
  const { boxesLimit } = useAuth()

  const {
    data: boxes,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBoxesQuery("boxesList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No boxes found") {
      return (content = (
        <DashModel title={"لا يوجد صندوق"} body={"لايوجد صندوق مرتبط بالمستخدم"} btnText={"انشاء جديد"} img={noBoxImg} link={"/dash/boxes/new"} />
      ));
    }
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  
  if (isSuccess) {
    let canAdd = !boxes.ids?.length || boxesLimit > boxes?.ids?.length
    const { ids } = boxes;

    const boxListContent =
      ids?.length &&
      ids.map((boxId) => <Box key={boxId} boxId={boxId} />);

    content = (
      <>
        {canAdd && 
            <button className="dash__main__btn" onClick={handleBtn}>
            اضافة صندوق جديد
            </button>
        }

        {/* return box card */}
        {boxListContent}
      </>
    );
  }

  return content;
};
export default BoxesList;
