import { Link } from "react-router-dom"

function Unauthorized({error}) {
  return (
    <div
      style={{
        display: "grid",
        height:'100vh',
        justifyItems: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{maxWidth: '450px'}}>
        <p className="errmsg" style={{textAlign: 'center'}}>
          {`${error}`}
        </p>
        <div style={{textAlign: 'center'}}>
          <Link to="/login" style={{border: '1px solid #ccc', padding: '10px 20px'}}>الرجاء تسجيل الدخول</Link>
          {/* <Login /> */}
        </div>
      </div>
    </div>
  );
}

export default Unauthorized;
