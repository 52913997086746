import { useNavigate } from "react-router-dom";
import { useGetGroupsQuery } from "./groupsApiSlice";
import Group from "./Group";
// import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle";
import DashModel from "../../components/DashModel";

import noGroupImg from "../../img/noGroup.png";
import Loading from "../../components/Loading";

const GroupsList = () => {
  useTitle("المجموعات");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/groups/new");
  };

  // const { username, isManager, isAdmin } = useAuth()

  const {
    data: groups,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetGroupsQuery("groupsList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <Loading />;

  if (isError) {
    if (error?.data?.message === "No groups found") {
      return (content = (
        <DashModel
          title={"لا يوجد مجموعات"}
          body={"لا يوجد مجموعات بالنظام"}
          btnText={"انشاء جديد"}
          img={noGroupImg}
          link={"/dash/groups/new"}
        />
      ));
    }
    content = <p className="errmsg">خطأ - {error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = groups;

    const tableContent =
      ids?.length &&
      ids.map((groupId) => <Group key={groupId} groupId={groupId} />);

    content = (
      <>
      <button className="dash__main__btn" onClick={handleBtn}>
          اضافة مجموعة
        </button>
        <table
          className="table table--notes"
          style={{ gridTemplateColumns: "2fr 1fr" }}
        >
          <thead className="table__thead">
            <tr>
              <th scope="col" className="table__th note__title">
                الاسم
              </th>
              <th scope="col" className="table__th note__edit">
                تعديل
              </th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </>
    );
  }

  return content;
};
export default GroupsList;
