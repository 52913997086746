import { useNavigate } from 'react-router-dom'

function DashModel({title, body, btnText, img, link}) {

    const navigate = useNavigate()
    const handleBtn = () => {
        navigate(link)
    }
  return (
    <div className="dash__model__container">
        <div className="dash__model__image__container">
            <img src={img} alt="صورة" />
        </div>
        <div className="dash__model__card__container">   
            <div className="dash__model__title__container">
                <p>{title}</p>
            </div>
            <div className="dash__model__body__container">
                <p>{body}</p>
            </div>
            <button className="dash__model__btn" onClick={handleBtn}>{btnText}</button>
        </div>
    </div>
  )
}

export default DashModel