import { useGetGroupsQuery } from "../groups/groupsApiSlice";
import { useGetBudgetsQuery } from "../budgets/budgetsApiSlice";
import { useGetBoxesQuery } from "../boxes/boxesApiSlice";
import { useGetTotalIncomesQuery } from "../incomes/incomesApiSlice";
import { useGetTotalPaymentsQuery } from "../payments/paymentsApiSlice";
import Loading from "../../components/Loading";
import ReportFilters from "./ReportFilters";

const ReportIndex = () => {
    const { groups, isLoading: groupsIsLoading } = useGetGroupsQuery(
        "groupsList",
        {
            selectFromResult: ({ data, isLoading }) => ({
                groups: data?.ids.map((id) => data?.entities[id]),
                isLoading,
            }),
        }
    );

    const { budgets, isLoading: budgetsIsLoading } = useGetBudgetsQuery(
        "budgetsList",
        {
            selectFromResult: ({ data, isLoading }) => ({
                budgets: data?.ids.map((id) => data?.entities[id]),
                isLoading,
            }),
        }
    );

    const { boxes, isLoading } = useGetBoxesQuery("boxesList", {
        selectFromResult: ({ data }) => ({
            boxes: data?.ids.map((id) => data?.entities[id]),
        }),
    });
    const { data: totalIncome, isLoading: totalIncomeIsLoading } =
        useGetTotalIncomesQuery("totalIncomes", {
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
        });
    const { data: totalPayments, isLoading: totalPaymentIsLoading } =
        useGetTotalPaymentsQuery("totalPayments", {
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
        });

    return (
        <div className="reports__index-container">
            <h3>الميزانية العامة</h3>
            <table className="table table-budget-report">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th">
                            الايرادات
                        </th>
                        <th scope="col" className="table__th">
                            المصروفات
                        </th>
                        <th scope="col" className="table__th">
                            الرصيد
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table__row">
                        <td className="table__cell">
                            {totalIncomeIsLoading || !totalIncome ? (
                                <Loading />
                            ) : (
                                Intl.NumberFormat("en-US").format(totalIncome)
                            )}
                        </td>
                        <td className="table__cell">
                            {totalPaymentIsLoading || !totalPayments ? (
                                <Loading />
                            ) : (
                                Intl.NumberFormat("en-US").format(totalPayments)
                            )}
                        </td>
                        <td className="table__cell">
                            {isLoading || !boxes?.length ? (
                                <Loading />
                            ) : (
                                Intl.NumberFormat("en-US").format(
                                    boxes[0].balance
                                )
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>

            {groupsIsLoading || budgetsIsLoading ? (
                <Loading />
            ) : (
                groups?.length &&
                budgets?.length && (
                    <ReportFilters budgets={budgets} groups={groups} />
                )
            )}
        </div>
    );
};

export default ReportIndex;
