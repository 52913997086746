import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetMembersQuery } from "../members/membersApiSlice";
import { useGetGroupsQuery } from "../groups/groupsApiSlice";
import useTitle from "../../hooks/useTitle";
import NewBudgetForm from "./NewBudgetForm";

import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";

const NewBudget = () => {
  useTitle("ميزانية جديدة");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/budgets");
  };


  const {
    data: members,
    isLoading,
    isError
  } = useGetMembersQuery("MembersList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {data: groups, isLoading:groupsIsLoading} = useGetGroupsQuery("GroupsList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });


  if(isLoading || groupsIsLoading) return <Loading />
  if(isError) return <p>لايوجد اعضاء  لانشاء ميزانية</p>
//  console.log(members.entities)
  const content = (
    <>
      <button className="dash__back__btn" onClick={handleBtn}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} />
        العودة للميزانيات
      </button>
      <NewBudgetForm members={members?.ids.map(id=> members?.entities[id])} groups={groups?.ids.map(id=> groups?.entities[id])} />
    </>
  );

  return content;
};
export default NewBudget;
