

import { memo } from 'react'
import SingleOperation from '../../components/SingleOperation'

const Income = ({ income }) => {


    if (income) {

        return (
                <SingleOperation key={income.id} income={income} isIncome={true} />
        )

    } else return null
}

const memoizedIncome = memo(Income)

export default memoizedIncome