import { useParams } from 'react-router-dom'
import EditBudgetForm from './EditBudgetForm'
import { useGetBudgetsQuery } from './budgetsApiSlice'
import { useGetMembersQuery } from '../members/membersApiSlice'
import useTitle from '../../hooks/useTitle'
import Loading from '../../components/Loading'

const EditBudget = () => {
    useTitle('تعديل الميزانية')

    const { id } = useParams()

    const { budget } = useGetBudgetsQuery("budgetsList", {
        selectFromResult: ({ data }) => ({
            budget: data?.entities[id]
        }),
    })

    const { members } = useGetMembersQuery("MembersList", {
        selectFromResult: ({ data }) => ({
          members: data?.ids.map((id) => data?.entities[id]),
        }),
      });


    if (!budget || !members) return <Loading />

    const content = <EditBudgetForm budget={budget} members={members} />

    return content
}
export default EditBudget