import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewReasonMutation } from "./reasonsApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewReasonForm = () => {
  const [addNewReason, { isLoading, isSuccess, isError, error }] =
    useAddNewReasonMutation();

  const navigate = useNavigate();

  const [name, setName] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setName("");
      navigate("/dash/reasons");
    }
  }, [isSuccess, navigate]);

  const onNameChanged = (e) => setName(e.target.value);

  const canSave = [name].every(Boolean) && !isLoading;

  const onSaveReasonClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewReason({ name });
    }
  };


  const errClass = isError ? "errmsg" : "offscreen";
  const validNameClass = !name ? "form__input--incomplete" : "";

  const content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="form" onSubmit={onSaveReasonClicked}>
        <div className="form__title-row">
          <h2>بند جديد</h2>
          <div className="form__action-buttons">
            <button className="icon-button" title="Save" disabled={!canSave}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </div>
        <label className="form__label" htmlFor="name">
          الاسم:
        </label>
        <input
          className={`form__input ${validNameClass}`}
          id="name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={onNameChanged}
        />
      </form>
    </>
  );

  return content;
};

export default NewReasonForm;
