import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewMemberMutation } from "./membersApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewMemberForm = ({ groups }) => {
  const [addNewMember, { isLoading, isSuccess, isError, error }] =
    useAddNewMemberMutation();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [groupId, setGroupId] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setName("");
      setPhone("");
      setDescription("");
      setGroupId("");
      setIsActive(true);
      navigate("/dash/members");
    }
  }, [isSuccess, navigate]);

  const onNameChanged = (e) => setName(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);
  const onIsActiveChanged = (e) => setIsActive(e.target.value);
  const onGroupIdChanged = (e) => setGroupId(e.target.value);

  const canSave = [name].every(Boolean) && !isLoading;

  const onSaveMemberClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewMember({ name, phone, description, isActive, groupId });
    }
  };

  let content;

  const options = groups?.map((group) => {
    return (
      <option key={group.id} value={group.id}>
        {" "}
        {group.name}
      </option>
    );
  });

  const errClass = isError ? "errmsg" : "offscreen";
  const validNameClass = !name ? "form__input--incomplete" : "";

  content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="form" onSubmit={onSaveMemberClicked}>
        <div className="form__title-row">
          <h2>عضو جديد</h2>
          <div className="form__action-buttons">
            <button className="icon-button" title="Save" disabled={!canSave}>
              <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </div>
        <label className="form__label" htmlFor="name">
          الاسم:
        </label>
        <input
          className={`form__input ${validNameClass}`}
          id="name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={onNameChanged}
        />
        <label className="form__label" htmlFor="phone">
          الجوال:
        </label>
        <input
          className={`form__input`}
          id="phone"
          name="phone"
          type="text"
          autoComplete="off"
          value={phone}
          onChange={onPhoneChanged}
        />
        
        <label
          className="form__label form__checkbox-container"
          htmlFor="groupId"
        >
          المجموعة:
        </label>
        <select
          id="groupId"
          name="groupId"
          className="form__select"
          value={groupId}
          onChange={onGroupIdChanged}
        >
            <option value="">بدون مجموعة</option>
          {options}
        </select>

        <label
          className="form__label form__checkbox-container"
          htmlFor="isActive"
        >
          حالة العضو:
        </label>
        <select
          id="isActive"
          name="isActive"
          className="form__select"
          value={isActive}
          onChange={onIsActiveChanged}
        >
          <option value={true}>فعال</option>
          <option value={false}>غير فعال</option>
        </select>
        <label className="form__label" htmlFor="member-description">
          وصف:
        </label>
        <input
          className={`form__input`}
          id="member-description"
          name="description"
          type="text"
          autoComplete="off"
          value={description}
          onChange={onDescriptionChanged}
        />
      </form>
    </>
  );

  return content;
};

export default NewMemberForm;
