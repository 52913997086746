import { Outlet } from "react-router-dom"
import NotAllowed from "../../components/NotAllowed"
import useAuth from "../../hooks/useAuth"

const RequireAuth = ({ allowedRoles }) => {
    const { roles } = useAuth()

    const content = (
        roles.some(role => allowedRoles.includes(role))
            ? <Outlet />
            : <NotAllowed />
    )

    return content
}
export default RequireAuth