import { Outlet } from 'react-router-dom'
import  useWindowSize  from '../hooks/useWindowSize'
import DashHeader from './DashHeader'
import DashFooter from './DashFooter'
import Footer from './mobile/Footer'

const DashLayout = () => {
    const size = useWindowSize();

    return (
        <>
            <DashHeader />
            <div className="dash-container">
                <Outlet />
            </div>
            <Footer />
            {/* {size.width > 768 ? <DashFooter /> : <Footer />} */}
        </>
    )
}
export default DashLayout