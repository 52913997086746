import { useParams } from 'react-router-dom'
import EditGroupForm from './EditGroupForm'
import { useGetGroupsQuery } from './groupsApiSlice'
import useTitle from '../../hooks/useTitle'
import Loading from '../../components/Loading'

const EditGroup = () => {
    useTitle('تعديل مجموعة')

    const { id } = useParams()

    const { group } = useGetGroupsQuery("groupsList", {
        selectFromResult: ({ data }) => ({
            group: data?.entities[id]
        }),
    })


    if (!group) return <Loading />

    const content = <EditGroupForm group={group} />

    return content
}
export default EditGroup