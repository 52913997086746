import { useState } from 'react'
import './singleOperation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'

function SingleOperation({income, isIncome}) {
    const [classHide, setClassHide] = useState(true)
    const onSingleOperationClicked = () => setClassHide(!classHide)
  return (
    <div className={`single-operation__main-container${!classHide ? '-big' : ""}`} onClick={onSingleOperationClicked}>
        <div className="single-operation__container">
            <FontAwesomeIcon icon={isIncome ? faCircleArrowDown : faCircleArrowUp} />
            <span className="single-operation__subtitle">{income.budget.name}</span>
            <p>{!isIncome ? income?.reason.name : income?.member.name}</p>
            <p>{income.amount}</p>
            <p className="single-operation__date">{new Date(income.date).toLocaleDateString('ar-SA')}</p>
        </div>
        <div className={`${classHide ? 'single-operation__hide' : ''}`}>
            <div className='single-operation__details'>
                <p>التاريخ : {new Date(income.date).toLocaleDateString('en-UK')}</p>
                <p>الرصيد قبل : {income.balanceBefore} ريال</p>
                <p>الرصيد بعد :{income.balanceAfter}ريال </p>
            </div>
            <p style={{padding: '0 10px'}}>الملاحظات :{income.description} </p>
        </div>
    </div>
  )
}

export default SingleOperation