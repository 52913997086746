import { useState, useEffect } from "react";
import {
  useUpdateBoxMutation,
  useDeleteBoxMutation,
} from "./boxesApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";

const EditBoxForm = ({ box }) => {
  const [updateBox, { isLoading, isSuccess, isError, error }] =
    useUpdateBoxMutation();

  const [
    deleteBox,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteBoxMutation();

  const navigate = useNavigate();

  const [title, setTitle] = useState(box.name);
  const [year, setYear] = useState(box.year);
  const [isClosed, setIsClosed] = useState(box.isClosed);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setTitle("");
      setYear("");
      setIsClosed(false);
      navigate("/dash/boxes");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onTitleChanged = (e) => setTitle(e.target.value);
  const onYearChanged = (e) => setYear(e.target.value);
  const onIsClosedChanged = (e) => setIsClosed((prev) => !prev);
  const onCancelClicked = (e) => navigate('/dash/boxes');
  const canSave = [title, year].every(Boolean) && !isLoading;

  const onSaveBoxClicked = async (e) => {
    if (canSave) {
      await updateBox({ id: box.id, title, year, isClosed });
    }
  };

  const onDeleteBoxClicked = async () => {
    await deleteBox({ id: box.id });
  };

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validTitleClass = !title ? "form__input--incomplete" : "";
  const validYearClass = !year ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let deleteButton = (
    <button className="icon-button" title="حذف" onClick={onDeleteBoxClicked}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
  let cancelButton = (
    <button className="icon-button" title="إلغاء" onClick={onCancelClicked}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form__title-row">
          <h2>تعديل الميزانية #{box.name}</h2>
          <div className="form__action-buttons">
          {cancelButton}
            <button
              className="icon-button"
              title="حفظ"
              onClick={onSaveBoxClicked}
              disabled={!canSave}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            {deleteButton}
          </div>
        </div>
        <label className="form__label" htmlFor="box-title">
          الاسم:
        </label>
        <input
          className={`form__input ${validTitleClass}`}
          id="box-title"
          name="title"
          type="text"
          autoComplete="off"
          value={title}
          onChange={onTitleChanged}
        />
        <label className="form__label" htmlFor="box-year">
          العام:
        </label>
        <input
          className={`form__input ${validYearClass}`}
          id="box-year"
          name="title"
          type="text"
          autoComplete="off"
          value={year}
          onChange={onYearChanged}
        />
        <div className="form__row">
          <label
            className="form__label form__checkbox-container"
            htmlFor="note-isClosed"
          >
            <input
              className="form__checkbox"
              id="note-isClosed"
              name="isClosed"
              type="checkbox"
              checked={isClosed}
              onChange={onIsClosedChanged}
            />
            مغلقة
          </label>
        </div>
      </form>
    </>
  );

  return content;
};

export default EditBoxForm;
