import { useParams } from 'react-router-dom'
import EditBoxForm from './EditBoxForm'
import { useGetBoxesQuery } from './boxesApiSlice'
import useTitle from '../../hooks/useTitle'
import Loading from '../../components/Loading'

const EditBox = () => {
    useTitle('تعديل الميزانية')

    const { id } = useParams()

    const { box } = useGetBoxesQuery("boxesList", {
        selectFromResult: ({ data }) => ({
            box: data?.entities[id]
        }),
    })


    if (!box) return <Loading />

    const content = <EditBoxForm box={box} />

    return content
}
export default EditBox