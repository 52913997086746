import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewBoxMutation } from "./boxesApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewBoxForm = () => {


    const [addNewBox, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewBoxMutation()

    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setName('')
            setDescription('')
            navigate('/dash')
        }
    }, [isSuccess, navigate])

    const onNameChanged = e => setName(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)

    const canSave = [name].every(Boolean) && !isLoading

    const onSaveBoxClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewBox({ name, description })
        }
    }


    const errClass = isError ? "errmsg" : "offscreen"
    const validNameClass = !name ? "form__input--incomplete" : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveBoxClicked}>
                <div className="form__title-row">
                    <h2>صندوق جديد</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="name">
                    الاسم:</label>
                <input
                    className={`form__input ${validNameClass}`}
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={onNameChanged}
                />
                <label className="form__label" htmlFor="description">
                    الوصف:</label>
                <input
                    className={`form__input`}
                    id="description"
                    name="description"
                    type="text"
                    autoComplete="off"
                    value={description}
                    onChange={onDescriptionChanged}
                />

            </form>
        </>
    )

    return content
}

export default NewBoxForm