import { useParams } from 'react-router-dom'
import EditReasonForm from './EditReasonForm'
import { useGetReasonsQuery } from './reasonsApiSlice'
import useTitle from '../../hooks/useTitle'
import Loading from '../../components/Loading'

const EditReason = () => {
    useTitle('تعديل البند')

    const { id } = useParams()

    const { reason } = useGetReasonsQuery("reasonsList", {
        selectFromResult: ({ data }) => ({
            reason: data?.entities[id]
        }),
    })


    if (!reason) return <Loading />

    const content = <EditReasonForm reason={reason} />

    return content
}
export default EditReason