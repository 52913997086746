import { store } from '../../app/store'
import { boxesApiSlice } from '../boxes/boxesApiSlice'
import { budgetsApiSlice } from '../budgets/budgetsApiSlice'
import { usersApiSlice } from '../users/usersApiSlice';
import { groupsApiSlice } from '../groups/groupsApiSlice';
import { membersApiSlice } from '../members/membersApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(boxesApiSlice.util.prefetch('getBoxes', 'boxesList', { force: true }))
        store.dispatch(budgetsApiSlice.util.prefetch('getBudgets', 'budgetsList', { force: true }))
        store.dispatch(membersApiSlice.util.prefetch('getMembers', 'membersList', { force: true }))
        store.dispatch(groupsApiSlice.util.prefetch('getGroups', 'groupsList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch
