import { useState } from "react";
import ReportFirstTable from "./ReportFirstTable";
import ReportSecondTable from "./ReportSecondTable";

function ReportFilters({ budgets, groups }) {
  const [targetBudget, setTargetBudget] = useState(budgets);
  const [budgetGroup, setBudgetGroup] = useState(groups);
  const [targetGroup, setTargetGroup] = useState(groups);
  const [groupMembers, setGroupMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  const onMemberChanged = (e) => {
    if (e.target.value === "all") {
        setSelectedMember(null)
    }else{
        setSelectedMember(groupMembers.find(m=> m._id.toString() === e.target.value.toString()))
    }

  }

  const onBudgetChanged = (e) => {
    // console.log(e.target.value)
    if (e.target.value === "all") {
      setTargetBudget((prev) => (prev = budgets));
      setTargetGroup((prev) => (prev = groups));
      setBudgetGroup((prev) => (prev = groups));
      document.getElementById("group").selectedIndex = "0";
    } else {
      document.getElementById("group").selectedIndex = "0";
      setTargetBudget(
        (prev) =>
          (prev = budgets.filter((budget) => budget.id === e.target.value))
      );
      const targetB = budgets.filter(
        (budget) => budget.id === e.target.value
      )[0];
      // console.log(targetB)
      setTargetGroup(
        (prev) =>
          (prev = groups.filter((group) =>
            [
              ...new Set(
                [...targetB.members, ...targetB.paidMembers].map(
                  (member) => member.group
                )
              ),
            ].includes(group.id)
          ))
      );
      setBudgetGroup(
        (prev) =>
          (prev = groups.filter((group) =>
            [
              ...new Set(
                [...targetB.members, ...targetB.paidMembers].map(
                  (member) => member.group
                )
              ),
            ].includes(group.id)
          ))
      );
    }
  };

  const onGroupChanged = (e) => {
    // console.log(e.target.value)
    if (e.target.value === "all") {
        setSelectedMember(null)
      if (targetBudget.length > 1) {
        setTargetGroup((prev) => (prev = groups));
      } else {
        document.getElementById("group").selectedIndex = "0";
        setTargetGroup(
          (prev) =>
            (prev = groups.filter((group) =>
              [
                ...new Set(
                  [
                    ...targetBudget[0].members,
                    ...targetBudget[0].paidMembers,
                  ].map((member) => member.group)
                ),
              ].includes(group.id)
            ))
        );
      }
    } else {
      setTargetGroup(
        (prev) =>
          (prev = budgetGroup.filter((group) => group.id === e.target.value))
      );
        // filter budgets members to the target group
        const allGroupMembers = targetBudget?.map(budget=> budget.members.filter(member=> member.group === e.target.value).concat(budget.paidMembers.filter(pMember=> pMember.group === e.target.value))).flat()
        const allGroupMembersIds = [...new Set(allGroupMembers.map(m=> m._id))]
        // console.log(allGroupMembers)
        const uMembers = []
        for(const mId of allGroupMembersIds){
            uMembers.push(allGroupMembers.find(m=> m._id === mId))
        }
        setGroupMembers(uMembers)
        // console.log(uMembers)
    }
  };

  const budgetsOptions = budgets?.map((budget) => {
    return (
      <option key={budget._id} value={budget.id}>
        {" "}
        {budget.name}
      </option>
    );
  });

  const groupsOptions = budgetGroup?.map((group) => {
    return (
      <option key={group._id} value={group.id}>
        {" "}
        {group.name}
      </option>
    );
  });

  const membersOptions = groupMembers?.map((member) => {
    return (
      <option key={member._id} value={member._id}>
        {" "}
        {member.name}
      </option>
    );
  });

  return (
    <div>
      <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        <div style={{ marginLeft: "20px", width: "200px" }}>
          <label
            className="form__label form__checkbox-container"
            htmlFor="budget"
          >
            الميزانية:
          </label>
          <select
            id="budget"
            name="budget"
            className="form__select report-filter-select"
            onChange={onBudgetChanged}
          >
            <option value="all">الكل</option>
            {budgetsOptions}
          </select>
        </div>
        <div style={{ marginLeft: "20px", width: "200px" }}>
          <label
            className="form__label form__checkbox-container"
            htmlFor="group"
          >
            المجموعة:
          </label>
          <select
            id="group"
            name="group"
            className="form__select report-filter-select"
            onChange={onGroupChanged}
          >
            <option key="all-groups" value="all">الكل</option>
            {groupsOptions}
          </select>
        </div>

        {/* show only when group is selected */}
        {targetGroup.length === 1 && (
          <div style={{ marginLeft: "20px", width: "200px" }}>
            <label
              className="form__label form__checkbox-container"
              htmlFor="group"
            >
              الاعضاء:
            </label>
            <select
              id="members-selector"
              name="membersSelector"
              className="form__select report-filter-select"
              onChange={onMemberChanged}
            >
              <option key="all-members" value="all">الكل</option>
              {membersOptions}
            </select>
          </div>
        )}
      </div>
      {groups && budgets && selectedMember ? <ReportSecondTable budgets={targetBudget} groups={targetGroup} member={selectedMember} /> : (
        <ReportFirstTable budgets={targetBudget} groups={targetGroup} />
      )}
    </div>
  );
}

export default ReportFilters;
