import { useState, useEffect } from "react";
import {
  useUpdateReasonMutation,
  useDeleteReasonMutation,
} from "./reasonsApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";

const EditReasonForm = ({ reason }) => {
  const [updateReason, { isLoading, isSuccess, isError, error }] =
    useUpdateReasonMutation();

  const [
    deleteReason,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteReasonMutation();

  const navigate = useNavigate();

  const [name, setName] = useState(reason.name);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setName("");
      navigate("/dash/reasons");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onNameChanged = (e) => setName(e.target.value);
  const onCancelClicked = (e) => navigate('/dash/reasons');

  const canSave = [name].every(Boolean) && !isLoading;

  const onSaveReasonClicked = async (e) => {
    if (canSave) {
      await updateReason({ id: reason.id, name});
    }
  };

  const onDeleteReasonClicked = async () => {
    await deleteReason({ id: reason.id });
  };

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validNameClass = !name ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let deleteButton = (
    <button className="icon-button" title="حذف" onClick={onDeleteReasonClicked}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
  let cancelButton = (
    <button className="icon-button" title="إلغاء" onClick={onCancelClicked}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form__title-row">
          <h2>تعديل بند الصرف</h2>
          <div className="form__action-buttons">
            {cancelButton}
            <button
              className="icon-button"
              title="حفظ"
              onClick={onSaveReasonClicked}
              disabled={!canSave}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            {deleteButton}
          </div>
        </div>
        <label className="form__label" htmlFor="reason-title">
          الاسم:
        </label>
        <input
          className={`form__input ${validNameClass}`}
          id="reason-name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={onNameChanged}
        />
      </form>
    </>
  );

  return content;
};

export default EditReasonForm;
