import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const budgetsAdapter = createEntityAdapter({
    // sortComparer: (a, b) => (a.createdAt === b.createdAt) ? 0 : a.createdAt ? 1 : -1
})

const initialState = budgetsAdapter.getInitialState()

export const budgetsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBudgets: builder.query({
            query: () => ({
                url: '/budgets',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const sortedResData = responseData.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const loadedBudgets = sortedResData.map(budget => {
                    budget.id = budget._id
                    return budget
                });
                return budgetsAdapter.setAll(initialState, loadedBudgets)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Budget', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Budget', id }))
                    ]
                } else return [{ type: 'Budget', id: 'LIST' }]
            }
        }),
        addNewBudget: builder.mutation({
            query: initialBudget => ({
                url: '/budgets',
                method: 'POST',
                body: {
                    ...initialBudget,
                }
            }),
            invalidatesTags: [
                { type: 'Budget', id: "LIST" }
            ]
        }),
        updateBudget: builder.mutation({
            query: initialBudget => ({
                url: '/budgets',
                method: 'PATCH',
                body: {
                    ...initialBudget,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Budget', id: arg.id }
            ]
        }),
        deleteBudget: builder.mutation({
            query: ({ id }) => ({
                url: `/budgets`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Budget', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetBudgetsQuery,
    useAddNewBudgetMutation,
    useUpdateBudgetMutation,
    useDeleteBudgetMutation,
} = budgetsApiSlice

// returns the query result object
export const selectBudgetsResult = budgetsApiSlice.endpoints.getBudgets.select()

// creates memoized selector
const selectBudgetsData = createSelector(
    selectBudgetsResult,
    budgetsResult => budgetsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllBudgets,
    selectById: selectBudgetById,
    selectIds: selectBudgetIds
    // Pass in a selector that returns the budgets slice of state
} = budgetsAdapter.getSelectors(state => selectBudgetsData(state) ?? initialState)