import React from "react";

function FirstTableTD({ group, budget }) {
  return (
    <>
      <td className="table__cell table__cell-report">{budget.name}</td>
      <td className="table__cell table__cell-report">
        {group.name} ({" "}
        {
          budget.members
            .concat(budget.paidMembers)
            .filter((member) => member.group === group.id).length
        }{" "}
        )
      </td>
      <td className="table__cell table__cell-report">
      <span className="table__cell-report-member">

        {
          budget.paidMembers.filter((member) => member.group === group.id)
            .length
        }{" فرد"}
      </span>
        {" "}
        {budget.paidMembers.filter((member) => member.group === group.id)
          .length * budget.amountForMember}{" ريال"}
      </td>
      <td className="table__cell table__cell-report">
        <span className="table__cell-report-member">
         {budget.members.filter((member) => member.group === group.id).length}{" فرد"}
        </span>
        {" "}
        {budget.members.filter((member) => member.group === group.id).length *
          budget.amountForMember}{" ريال"}
        
      </td>
    </>
  );
}

export default FirstTableTD;
