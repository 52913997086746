import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseUser, faSackDollar, faCircleInfo, faFileLines, faCoins } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

import "./footer.css";
function Footer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onGoHomeClicked = () => navigate("/dash");
  const onGoReportsClicked = () => navigate("/dash/reports");
  const onGoPaymentClicked = () => navigate("/dash/payments/new");
  const onGoMoreClicked = () => navigate("/dash/more");
  const onGoIncomeClicked = () => navigate("/dash/incomes/new");

  let goHomeButton = (
    <button
      className={`mobile-footer__button mobile-icon-button ${
        pathname === "/dash" && "mobile__button__selected"
      }`}
      title="الرئيسية"
      onClick={onGoHomeClicked}
    >
      <FontAwesomeIcon icon={faHouseUser} />
      <span>الرئيسية</span>
    </button>
  );

  let goPaymentButton = (
    <button
      className={`mobile-footer__button mobile-icon-button ${
        pathname === "/dash/payments/new" && "mobile__button__selected"
      }`}
      title="صرف"
      onClick={onGoPaymentClicked}
    >
      <FontAwesomeIcon icon={faCoins} />
      <span>صرف</span>
    </button>
  );

  let goGroupsButton = (
    <button
      className={`mobile-footer__button mobile-icon-button ${
        pathname === "/dash/reports" && "mobile__button__selected"
      }`}
      title="تقارير"
      onClick={onGoReportsClicked}
    >
      <FontAwesomeIcon icon={faFileLines} />
      <span>تقارير</span>
    </button>
  );

  let goMoreButton = (
    <button
      className={`mobile-footer__button mobile-icon-button ${
        pathname === "/dash/more" && "mobile__button__selected"
      }`}
      title="المزيد"
      onClick={onGoMoreClicked}
    >
      <FontAwesomeIcon icon={faCircleInfo} />
      <span>المزيد</span>
    </button>
  );

  let goIncomeButton = (
    <button
      className={`mobile-footer__button mobile-icon-button ${
        pathname === "/dash/incomes/new" && "mobile__button__selected"
      }`}
      title="ايداع"
      onClick={onGoIncomeClicked}
    >
      <FontAwesomeIcon icon={faSackDollar} />
      <span>إيداع</span>
    </button>
  );
  return (
    <footer className="mobile-footer">
      {goHomeButton}
      {goIncomeButton}
      {goPaymentButton}
      {goGroupsButton}
      {goMoreButton}
    </footer>
  );
}

export default Footer;
