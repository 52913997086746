import { useParams } from 'react-router-dom'
import EditMemberForm from './EditMemberForm'
import { useGetMembersQuery } from './membersApiSlice'
import { useGetGroupsQuery } from '../groups/groupsApiSlice'
import useTitle from '../../hooks/useTitle'
import Loading from '../../components/Loading'

const EditMember = () => {
    useTitle('تعديل الميزانية')

    const { id } = useParams()

    const { member } = useGetMembersQuery("membersList", {
        selectFromResult: ({ data }) => ({
            member: data?.entities[id]
        }),
    })

    const { groups } = useGetGroupsQuery("groupsList", {
        selectFromResult: ({ data }) => ({
            groups: data?.ids.map(id => data?.entities[id])
        }),
    })


    if (!member) return <Loading />

    const content = <EditMemberForm member={member} groups={groups} />

    return content
}
export default EditMember