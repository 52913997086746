

import "./boxCard.css";
import { useGetBudgetIncomesQuery } from "../features/incomes/incomesApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

function BoxCard({ budget }) {
  const {
    data: incomes,
    isLoading: isLoadingIncomes,
    isError: isErrorIncomes,
    error: errorIncomes,
    isSuccess: isSuccessIncome,
  } = useGetBudgetIncomesQuery(budget.id);

  if (isErrorIncomes) {
    if(errorIncomes?.data?.message !== 'No income found'){
        return <p>خطأ</p>
    }
  }
    let balance = 0;
    if(isSuccessIncome){
        for (const income of incomes) {
          balance = income.amount + balance;
        }
    }
    return (
      <div style={{ width: "100%", marginLeft: "10px" }}>
        <div className={`box-card__container ${isLoadingIncomes ? ' skeleton' : ''}`}>
          <div className="box-card__header">
            <FontAwesomeIcon icon={faCreditCard} />
            <span>SAR</span>
          </div>
          <div className="box-card__title">
            <p>{budget.name}</p>
          </div>
          <div className="box-card__body">
            <span>{Intl.NumberFormat("en-US").format(balance)}</span>
          </div>
        </div>
      </div>
    );
  }

export default BoxCard;
