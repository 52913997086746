import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetBudgetsQuery } from './budgetsApiSlice'
import { memo } from 'react'

const Budget = ({ budgetId, budget }) => {

    // const { budget } = useGetBudgetsQuery("budgetsList", {
    //     selectFromResult: ({ data }) => ({
    //         budget: data?.entities[budgetId]
    //     }),
    // })

    const navigate = useNavigate()

    if (budget) {

        const handleEdit = () => navigate(`/dash/budgets/${budgetId}`)

        return (
            <tr className="table__row">
                <td className="table__cell note__status">
                    {budget.closed
                        ? <span className="note__status--closed">مغلقة</span>
                        : <span className="note__status--open">مفتوحة</span>
                    }
                </td>
                <td className="table__cell note__title">{budget.name}</td>
                <td className="table__cell note__username">{budget.year}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedBudget = memo(Budget)

export default memoizedBudget