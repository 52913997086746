import { useState, useEffect } from "react";
import {
  useUpdateMemberMutation,
  useDeleteMemberMutation,
} from "./membersApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const EditMemberForm = ({ member, groups }) => {
  const [updateMember, { isLoading, isSuccess, isError, error }] =
    useUpdateMemberMutation();

  const [
    deleteMember,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteMemberMutation();

  const navigate = useNavigate();

  const [name, setName] = useState(member.name);
  const [phone, setPhone] = useState(member.phone);
  const [isActive, setIsActive] = useState(member.isActive);
  const [groupId, setGroupId] = useState(member.group?._id);
  const [description, setDescription] = useState(member.description);
  

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setName("");
      setPhone("");
      setIsActive(false);
      navigate("/dash/members");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onNameChanged = (e) => setName(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onIsActiveChanged = (e) => setIsActive((prev) => !prev);
  const onGroupIdChanged = (e) => setGroupId(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const canSave = [name].every(Boolean) && !isLoading;

  const onSaveMemberClicked = async (e) => {
    if (canSave) {
      await updateMember({ id: member.id, name, phone, isActive, description, groupId });
    }
  };

  const onDeleteMemberClicked = async () => {
    await deleteMember({ id: member.id });
  };

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validNameClass = !name ? "form__input--incomplete" : "";
//   const validPhoneClass = !phone ? "form__input--incomplete" : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let deleteButton = (
    <button className="icon-button" title="حذف" onClick={onDeleteMemberClicked}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
  const options = groups?.map((group) => {
    return (
      <option key={group.id} value={group.id}>
        {" "}
        {group.name}
      </option>
    );
  });

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form__title-row">
          <h2>تعديل عضو</h2>
          <div className="form__action-buttons">
            <button
              className="icon-button"
              title="حفظ"
              onClick={onSaveMemberClicked}
              disabled={!canSave}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            {deleteButton}
          </div>
        </div>
        <label className="form__label" htmlFor="member-name">
          الاسم:
        </label>
        <input
          className={`form__input ${validNameClass}`}
          id="member-name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={onNameChanged}
        />
        <label className="form__label" htmlFor="member-phone">
          الجوال:
        </label>
        <input
          className="form__input"
          id="member-phone"
          name="phone"
          type="text"
          pattern="[0-9]*"
          autoComplete="off"
          value={phone}
          onChange={onPhoneChanged}
        />
        <div className="form__row">
          <label
            className="form__label form__checkbox-container"
            htmlFor="note-isActive"
          >
            <input
              className="form__checkbox"
              id="note-isActive"
              name="isActive"
              type="checkbox"
              checked={isActive}
              onChange={onIsActiveChanged}
            />
            فعال
          </label>
        </div>

        <label
          className="form__label form__checkbox-container"
          htmlFor="groupId"
        >
          المجموعة:
        </label>
        <select
          id="groupId"
          name="groupId"
          className="form__select"
          defaultValue={groupId}
          onChange={onGroupIdChanged}
        >
            <option value="">بدون مجموعة</option>
          {options}
        </select>

        <label className="form__label" htmlFor="member-description">
          وصف:
        </label>
        <input
          className={`form__input`}
          id="member-description"
          name="description"
          type="text"
          autoComplete="off"
          value={description}
          onChange={onDescriptionChanged}
        />
      </form>
    </>
  );

  return content;
};

export default EditMemberForm;
