import FirstTableTD from "./FirstTableTD";

function ReportFirstTable({ budgets, groups }) {
    // console.log(budgets)
  const tableContent = budgets.map((budget) => (
    <tr key={budget._id} className="table__row">
      {groups
        .filter((group) =>
          [...new Set(budget.members.concat(budget.paidMembers).map((member) => member.group))].includes(
            group.id
          )
        )
        .map((group) => (
          <FirstTableTD group={group} budget={budget} key={group._id} />
        ))}
    </tr>
  ));
  return (
    <div style={{ overflow: "scroll", marginTop: "20px", overflowY: "hidden" }}>
      <table className="table table-first-report">
        <thead className="table__thead">
          <tr>
            <th scope="col" className="table__th table__th-report">
              الميزانية
            </th>
            <th scope="col" className="table__th table__th-report">
              المجموعة
            </th>
            <th scope="col" className="table__th table__th-report">
              الايرادات
            </th>
            <th scope="col" className="table__th table__th-report">
              المتأخرات
            </th>
          </tr>
        </thead>
        <tbody>
          {tableContent}
        </tbody>
      </table>
    </div>
  );
}

export default ReportFirstTable;
