import './actionButton.css'

function ActionButton({children, title}) {
    return (
        <div className='action-button__container'>
            <div className='action-button__title'>
                {children}
            </div>
            <div className='action-button__body'>
                <p>{title}</p>
            </div>
        </div>
      )
}

export default ActionButton