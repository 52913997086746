
import './dashCard.css'


function DashCard({children, title, icon}) {
  return (
    <div className='dash-card__container'>
        <div className='dash-card__title'>
            {children}
        </div>
        <div className='dash-card__body'>
            <p>{title}</p>
        </div>
    </div>
  )
}

export default DashCard