import './addButton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'


function AddButton({text, link}) {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate(link)
    }
  return (
    <div style={{width: '100%', marginLeft: '10px'}} onClick={handleNavigate}>
        <div className='add-button__container'>
            <div className="add-button__body">
                <FontAwesomeIcon icon={faCirclePlus} />
                <p>{text}</p>
            </div>
        </div>
    </div>
  )
}

export default AddButton