import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewBudgetMutation } from "./budgetsApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewBudgetForm = ({ members, groups }) => {
    //   console.log('members',members)

    const [addNewBudget, { isLoading, isSuccess, isError, error }] =
        useAddNewBudgetMutation();

    const navigate = useNavigate();

    const [allMembers, setAllMembers] = useState(members);
    const [budgetMembers, setBudgetMembers] = useState([]);
    const [title, setTitle] = useState("");
    const [year, setYear] = useState("");
    const [amount, setAmount] = useState(0);
    const [groupId, setGroupId] = useState("all");

    useEffect(() => {
        if (isSuccess) {
            setTitle("");
            setYear("");
            setAmount(0);
            navigate("/dash/boxes");
        }
    }, [isSuccess, navigate]);

    const onTitleChanged = (e) => setTitle(e.target.value);
    const onYearChanged = (e) => setYear(e.target.value);
    const onAmountChanged = (e) => setAmount(e.target.value);

    const addMember = (member) => {
        setAllMembers(allMembers.filter((m) => m.id !== member.id));
        setBudgetMembers((prevState) => [...prevState, member]);
    };
    const removeMember = (member) => {
        setBudgetMembers(budgetMembers.filter((m) => m.id !== member.id));
        setAllMembers((prevState) => [...prevState, member]);
    };
    const onGroupIdChanged = (e) => {
        setGroupId((prevState) => (prevState = e.target.value));
        if (e.target.value === "all") {
            setAllMembers(
                (prevState) =>
                    (prevState = members.filter(
                        (m) => !budgetMembers.map((bm) => bm.id).includes(m.id)
                    ))
            );
            return;
        }
        setAllMembers((prevState) =>
            (prevState = members.filter(
                (m) => !budgetMembers.map((bm) => bm.id).includes(m.id)
            )).filter((m) => m?.group?._id === e.target.value)
        );
    };

    const addAllSelectedMembers = () => {
        setAllMembers([]);
        setBudgetMembers((prevState) => [...prevState, ...allMembers]);
    };

    const removeAllSelectedMembers = () => {
        setBudgetMembers([]);
        setAllMembers(members);
    };

    const canSave =
        [title, year, amount, budgetMembers.length].every(Boolean) &&
        !isLoading;

    const onSaveBudgetClicked = async (e) => {
        e.preventDefault();
        const memberIds = budgetMembers.map((member) => member.id);
        if (canSave) {
            await addNewBudget({ name: title, year, amount, memberIds });
        }
    };

    const errClass = isError ? "errmsg" : "offscreen";
    const validTitleClass = !title ? "form__input--incomplete" : "";
    const validYearClass = !year ? "form__input--incomplete" : "";
    const validAmountClass =
        !amount && !Number(amount) ? "form__input--incomplete" : "";
    const validMembersClass = !budgetMembers?.length
        ? "form__input--incomplete"
        : "";

    const options = groups?.map((group) => {
        return (
            <option key={group.id} value={group.id}>
                {" "}
                {group.name}
            </option>
        );
    });

    const allMembersContent = allMembers?.map((member, index) => {
        return (
            <div
                onClick={() => addMember(member)}
                key={member.id}
                className="add-budget__member"
            >
                <p>
                    {index + 1} {member.name} - {member?.group?.name}
                </p>
                <p style={{ color: "green", fontSize: "24px" }}>+</p>
            </div>
        );
    });

    const budgetMembersContent = budgetMembers.map((member, index) => {
        return (
            <div
                onClick={() => removeMember(member)}
                key={member.id}
                className="add-budget__member"
            >
                <p>
                    {index + 1}- {member.name}
                </p>
                <p style={{ color: "tomato", fontSize: "24px" }}>-</p>
            </div>
        );
    });

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveBudgetClicked}>
                <div className="form__title-row">
                    <h2>ميزانية جديدة</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="title">
                    الاسم:
                </label>
                <input
                    className={`form__input ${validTitleClass}`}
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="off"
                    value={title}
                    onChange={onTitleChanged}
                />
                <label className="form__label" htmlFor="year">
                    العام:
                </label>
                <input
                    className={`form__input ${validYearClass}`}
                    id="year"
                    name="year"
                    type="text"
                    pattern="[0-9]*"
                    autoComplete="off"
                    value={year}
                    onChange={onYearChanged}
                />
                <label className="form__label" htmlFor="amount">
                    المبلغ لكل عضو:
                </label>
                <input
                    className={`form__input ${validAmountClass}`}
                    id="amount"
                    name="amount"
                    type="number"
                    pattern="[0-9]*"
                    autoComplete="off"
                    value={amount}
                    onChange={onAmountChanged}
                />
            </form>
            <div style={{ marginTop: "10px" }}>
                <label className="form__label" style={{ marginTop: "5px" }}>
                    اعضاء الميزانية: {budgetMembers.length}
                </label>
                <button
                    style={{
                        marginRight: "10px",
                        padding: "3px 16px",
                        borderRadius: "8px",
                    }}
                    onClick={removeAllSelectedMembers}
                >
                    حذف الكل
                </button>
                <div
                    className={`add-budget__members__container ${validMembersClass}`}
                >
                    {!budgetMembers.length ? (
                        <p>لا يوجد اعضاء</p>
                    ) : (
                        budgetMembersContent
                    )}
                </div>
            </div>
            <div style={{ marginTop: "30px" }}>
                <label
                    className="form__label"
                    style={{ marginTop: "15px", marginLeft: "30px" }}
                >
                    جميع الاعضاء:{allMembers?.length}
                </label>
                <select
                    id="groupId"
                    name="groupId"
                    className="form__select"
                    value={groupId}
                    onChange={onGroupIdChanged}
                >
                    <option value="all">الكل</option>
                    {options}
                </select>
                <button
                    style={{
                        marginRight: "10px",
                        padding: "3px 16px",
                        borderRadius: "8px",
                    }}
                    onClick={addAllSelectedMembers}
                >
                    اضافة الكل
                </button>
                <div className="add-budget__members__container">
                    {allMembersContent}
                </div>
            </div>
        </>
    );

    return content;
};

export default NewBudgetForm;
