import './balanceCard.css'
import {useGetBoxesQuery} from '../features/boxes/boxesApiSlice'

function BalanceCard({ boxId, text }) {

    const { box } = useGetBoxesQuery("boxesList", {
        selectFromResult: ({ data }) => ({
            box: data?.entities[boxId]
        }),
    })
  return (
    <div className='balance-card__container'>
        <div className="balance-card__header">
            <p>اجمالي الرصيد المتوفر</p>
            <span>SAR</span>
        </div>
        <div className="balance-card__title">
            <p>{Intl.NumberFormat('en-US').format(box.balance)}</p>
        </div>
        <div className="balance-card__body">
            <span>{text}</span>
        </div>
    </div>
  )
}

export default BalanceCard