import { useGetBoxesQuery } from "./boxesApiSlice";
import { useGetBudgetsQuery } from "../budgets/budgetsApiSlice";
import { memo } from "react";
import BoxCard from "../../components/BoxCard";
import BalanceCard from "../../components/BalanceCard";
import AddButton from "../../components/AddButton";
import Loading from "../../components/Loading";
import ActionButtonForBox from "../../components/ActionButtonForBox";
import LastOperations from "../../components/LastOperations";
import { Link } from "react-router-dom";

const Box = ({ boxId }) => {
  const { box } = useGetBoxesQuery("boxesList", {
    selectFromResult: ({ data }) => ({
      box: data?.entities[boxId],
    }),
  });

  const {
    data: budgets,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBudgetsQuery("budgetsList", {
    // pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (box) {
    let budgetsContent;
    if (isLoading) budgetsContent = <Loading />;
    if (isError) {
      if (error?.data?.message === "No budgets found") {
        budgetsContent = null;
      }
    }

    if (isSuccess) {
      const { ids } = budgets;

      budgetsContent =
        ids?.length &&
        ids.map((budgetId) => (
          <Link key={budgetId} to={`/dash/budgets/${budgetId}`}>
            <BoxCard budgetId={budgetId} budget={budgets.entities[budgetId]} />
          </Link>
        ));
    }
    return (
      // return box card
      <>
        <BalanceCard boxId={box.id} text={box.name} />

        <div style={{ display: "flex", overflow: "auto", maxWidth: "768px", margin:'0 auto' }}>
          {budgetsContent}
          <Link to="/dash/budgets/new">
            <AddButton text={"ميزانية جديدة"} link={"/dash/budgets/new"} />
          </Link>
        </div>
        <div style={{ borderBottom: "3px solid #ccc", height: "20px", maxWidth: "768px", margin: "0 auto" }} />
        <ActionButtonForBox />
        <div style={{ borderBottom: "3px solid #ccc", height: "20px", maxWidth: "768px", margin: "0 auto" }} />
        {/* <LastOperations /> */}
      </>
    );
  } else return null;
};

const memoizedBox = memo(Box);

export default memoizedBox;
