import {Link} from 'react-router-dom'
import ActionButton from './ActionButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar, faFileLines, faCoins } from "@fortawesome/free-solid-svg-icons";

function ActionButtonForBox() {
    let content = (
        <>
            <div className="action-buttons__container">
                <Link to={`/dash/incomes`}>
                    <ActionButton title={"الايداعات"}>
                        <FontAwesomeIcon icon={faSackDollar} />
                    </ActionButton>
                </Link>
                <Link to='/dash/payments'>
                    <ActionButton title={'المصروفات'}>
                        <FontAwesomeIcon icon={faCoins} />
                    </ActionButton>
                </Link>
                <Link to='/dash/reports'>
                    <ActionButton title={'التقارير'}>
                        <FontAwesomeIcon icon={faFileLines} />
                    </ActionButton>
                </Link>
            </div>
        </>
    )

    return content;
}

export default ActionButtonForBox