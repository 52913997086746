import { Link } from 'react-router-dom'

const Public = () => {

    const content = (
        <section className="public">
            <header>
                <h1>الصندوق العائلي</h1>
            </header>
            <main className="public__main">
                <p>لادارة الصندوق العائلي</p>
                <div style={{ textAlign: 'center' }}>
                    <Link to="/dash" style={{ display: 'block', border: '1px solid #ccc', padding: '10px 20px' }}>لوحة التحكم</Link>
                    <Link to="/login" style={{ display: 'block', border: '1px solid #ccc', padding: '10px 20px' }}> تسجيل الدخول</Link>
                </div>

            </main>
            <footer>
            </footer>
        </section>

    )
    return content
}
export default Public