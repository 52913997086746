import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const boxesAdapter = createEntityAdapter({})

const initialState = boxesAdapter.getInitialState()

export const boxesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBoxes: builder.query({
            query: () => ({
                url: '/boxes',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedBoxes = responseData.map(box => {
                    box.id = box._id
                    return box
                });
                return boxesAdapter.setAll(initialState, loadedBoxes)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Box', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Box', id }))
                    ]
                } else return [{ type: 'Box', id: 'LIST' }]
            }
        }),
        addNewBox: builder.mutation({
            query: initialBox => ({
                url: '/boxes',
                method: 'POST',
                body: {
                    ...initialBox,
                }
            }),
            invalidatesTags: [
                { type: 'Box', id: "LIST" }
            ]
        }),
        updateBox: builder.mutation({
            query: initialBox => ({
                url: '/boxes',
                method: 'PATCH',
                body: {
                    ...initialBox,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Box', id: arg.id }
            ]
        }),
        deleteBox: builder.mutation({
            query: ({ id }) => ({
                url: `/boxes`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Box', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetBoxesQuery,
    useAddNewBoxMutation,
    useUpdateBoxMutation,
    useDeleteBoxMutation,
} = boxesApiSlice

// returns the query result object
export const selectBoxesResult = boxesApiSlice.endpoints.getBoxes.select()

// creates memoized selector
const selectBoxesData = createSelector(
    selectBoxesResult,
    boxesResult => boxesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllBoxes,
    selectById: selectBoxById,
    selectIds: selectBoxIds
    // Pass in a selector that returns the boxes slice of state
} = boxesAdapter.getSelectors(state => selectBoxesData(state) ?? initialState)