import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const reasonsAdapter = createEntityAdapter({})

const initialState = reasonsAdapter.getInitialState()

export const reasonsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReasons: builder.query({
            query: () => ({
                url: '/reasons',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedReasons = responseData.map(reason => {
                    reason.id = reason._id
                    return reason
                });
                return reasonsAdapter.setAll(initialState, loadedReasons)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Reason', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Reason', id }))
                    ]
                } else return [{ type: 'Reason', id: 'LIST' }]
            }
        }),
        addNewReason: builder.mutation({
            query: initialReasonData => ({
                url: '/reasons',
                method: 'POST',
                body: {
                    ...initialReasonData,
                }
            }),
            invalidatesTags: [
                { type: 'Reason', id: "LIST" }
            ]
        }),
        updateReason: builder.mutation({
            query: initialReasonData => ({
                url: '/reasons',
                method: 'PATCH',
                body: {
                    ...initialReasonData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Reason', id: arg.id }
            ]
        }),
        deleteReason: builder.mutation({
            query: ({ id }) => ({
                url: `/reasons`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Reason', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetReasonsQuery,
    useAddNewReasonMutation,
    useUpdateReasonMutation,
    useDeleteReasonMutation,
} = reasonsApiSlice

// returns the query result object
export const selectReasonsResult = reasonsApiSlice.endpoints.getReasons.select()

// creates memoized selector
const selectReasonsData = createSelector(
    selectReasonsResult,
    reasonsResult => reasonsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllReasons,
    selectById: selectReasonById,
    selectIds: selectReasonIds
    // Pass in a selector that returns the reasons slice of state
} = reasonsAdapter.getSelectors(state => selectReasonsData(state) ?? initialState)