import { useState, useEffect } from "react";
import {
  useUpdateBudgetMutation,
  useDeleteBudgetMutation,
} from "./budgetsApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";

const EditBudgetForm = ({ budget, members }) => {
  const [updateBudget, { isLoading, isSuccess, isError, error }] =
    useUpdateBudgetMutation();

  const budgetMembersIds = budget.members
    .concat(budget.paidMembers)
    .map((m) => m._id);
  const [
    deleteBudget,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteBudgetMutation();

  const navigate = useNavigate();

  const [allMembers, setAllMembers] = useState(members);
  const [title, setTitle] = useState(budget.name);
  const [year, setYear] = useState(budget.year);
  const [isClosed, setIsClosed] = useState(budget.isClosed);
  const [amount, setAmount] = useState(budget.amountForMember);
  const [budgetMembers, setBudgetMembers] = useState(budget.members);

  useEffect(() => {
    setAllMembers(allMembers.filter((m) => !budgetMembersIds.includes(m.id)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setTitle("");
      setYear("");
      setIsClosed(false);
      navigate("/dash/boxes");
    }
  }, [isSuccess, isDelSuccess, navigate]);

  const onTitleChanged = (e) => setTitle(e.target.value);
  const onYearChanged = (e) => setYear(e.target.value);
  const onIsClosedChanged = (e) => setIsClosed((prev) => !prev);
  const onAmountChanged = (e) => setAmount(e.target.value);
  const onCancelClicked = (e) => navigate("/dash/boxes");
  const canSave = [title, year].every(Boolean) && !isLoading;

  const onSaveBudgetClicked = async (e) => {
    if (canSave) {
      await updateBudget({
        id: budget.id,
        name: title,
        year,
        isClosed,
        memberIds: budgetMembers.map((m) => m.id),
        amount: Number(amount),
      });
    }
  };

  const onDeleteBudgetClicked = async () => {
    await deleteBudget({ id: budget.id });
  };
  const removeMember = (member) => {
    setBudgetMembers(budgetMembers.filter((m) => m._id !== member.id));
    setAllMembers((prevState) => [...prevState, member]);
  };
  const addMember = (member) => {
    setAllMembers(allMembers.filter((m) => m.id !== member.id));
    setBudgetMembers((prevState) => [...prevState, member]);
  };

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validTitleClass = !title ? "form__input--incomplete" : "";
  const validAmountClass =
    !amount && !Number(amount) ? "form__input--incomplete" : "";
  const validYearClass = !year ? "form__input--incomplete" : "";
  const validMembersClass = !budgetMembers?.length
    ? "form__input--incomplete"
    : "";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  let deleteButton = (
    <button className="icon-button" title="حذف" onClick={onDeleteBudgetClicked}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
  let cancelButton = (
    <button className="icon-button" title="إلغاء" onClick={onCancelClicked}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );

  const budgetMembersContent = budgetMembers.map((member) => {
    return (
      <div
        onClick={() => removeMember(member)}
        key={member.id}
        className="add-budget__member"
      >
        <p>{member.name}</p>
        <p style={{ color: "tomato", fontSize: "24px" }}>-</p>
      </div>
    );
  });

  const allMembersContent = allMembers.map((member) => {
    return (
      <div
        onClick={() => addMember(member)}
        key={member.id}
        className="add-budget__member"
      >
        <p>{member.name}</p>
        <p style={{ color: "green", fontSize: "24px" }}>+</p>
      </div>
    );
  });

  const content = (
    <>
      <p className={errClass}>{errContent}</p>

      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="form__title-row">
          <div>
            <h4>تعديل الميزانية</h4>
            <p>{budget.name}</p>
          </div>
          <div className="form__action-buttons">
            {cancelButton}
            <button
              className="icon-button"
              title="حفظ"
              onClick={onSaveBudgetClicked}
              disabled={!canSave}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            {/* {deleteButton} */}
          </div>
        </div>
        <label className="form__label" htmlFor="budget-title">
          الاسم:
        </label>
        <input
          className={`form__input ${validTitleClass}`}
          id="budget-title"
          name="title"
          type="text"
          autoComplete="off"
          value={title}
          onChange={onTitleChanged}
        />
        <label className="form__label" htmlFor="year">
          العام:
        </label>
        <input
          className={`form__input ${validYearClass}`}
          id="year"
          name="year"
          type="text"
          pattern="[0-9]*"
          autoComplete="off"
          value={year}
          onChange={onYearChanged}
        />
        <label className="form__label" htmlFor="amount">
          المبلغ لكل عضو:{" "}
          <span
            style={{ fontSize: "10px", color: "tomato", marginRight: "10px" }}
          >
            {budget?.paidMembers.length
              ? "لوجود مبالغ مسلمة للميزانية لا يمكن تعديل المبلغ"
              : ""}
          </span>
        </label>
        <input
          className={`form__input ${validAmountClass}`}
          id="amount"
          name="amount"
          type="number"
          pattern="[0-9]*"
          autoComplete="off"
          value={amount}
          onChange={onAmountChanged}
          disabled={budget?.paidMembers.length && true}
        />
        <div className="form__row">
          <label
            className="form__label form__checkbox-container"
            htmlFor="note-isClosed"
          >
            <input
              className="form__checkbox"
              id="note-isClosed"
              name="isClosed"
              type="checkbox"
              checked={isClosed}
              onChange={onIsClosedChanged}
            />
            مغلقة
          </label>
        </div>
      </form>

      <label className="form__label" style={{ marginTop: "5px" }}>
        اعضاء الميزانية: {budgetMembers.length}
      </label>
      <div className={`add-budget__members__container ${validMembersClass}`}>
        {!budgetMembers.length ? <p>لا يوجد اعضاء</p> : budgetMembersContent}
      </div>
      <label className="form__label" style={{ marginTop: "15px" }}>
        جميع الاعضاء: {allMembers.length}
      </label>
      <div className="add-budget__members__container">{allMembersContent}</div>
    </>
  );

  return content;
};

export default EditBudgetForm;
