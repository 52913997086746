import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useTitle from "../../hooks/useTitle";
import NewBoxForm from "./NewBoxForm";

import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const NewBox = () => {
  useTitle("صندوق جديد");
  const navigate = useNavigate();
  const handleBtn = () => {
    navigate("/dash/boxes");
  };

  const content = (
    <>
      <button className="dash__back__btn" onClick={handleBtn}>
      <FontAwesomeIcon icon={faArrowAltCircleRight} />
        العودة للصناديق
      </button>
      <NewBoxForm />
    </>
  );

  return content;
};
export default NewBox;
